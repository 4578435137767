<template>
  <div>
         <!-- Background Element start -->
                       <!-- 1st Set -->
                              

                                <div class="mt-5  d-flex align-items-center justify-content-center" style="height:100px">

                                    <div >
                                        <button @click="triggerModal" class=" btn px-3 py-2" :style="`background:${PopUpDesigns.buttonColor};color:${PopUpDesigns.buttonTextColor};border-radius:${PopUpDesigns.borderRadius}px;font-size:${PopUpDesigns.fontSize}px;`">{{PopUpDesigns.buttonText}}</button>
                                    </div>
                                </div>

                               

                        <!-- Background Element end -->

                        <StaticPopUp :isDesktopView="isDesktopView"
                    :pageDesign="pageDesign" :PopUpDesigns="PopUpDesigns" />
  </div>
</template>

<script>
import StaticPopUp from "./StaticPopUp.vue"
export default {
props:["PopUpDesigns","isDesktopView","pageDesign"],
components:{
  StaticPopUp
},
methods:{
  triggerModal(){
    
    let modal = document.getElementById("quizPopupModal");
    modal.style.display = "block";
  }
}
}
</script>

<style>

</style>